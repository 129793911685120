import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import {
    keyExpired,
    keyUser,
    keyToken,
    keyAppVersion,
    keyMetaData,
    keyGuestCart,
    keyGuestCartId,
    keyGuestEventCart,
} from 'variable';
import { saveAuthToken, removeAuthToken, getAuthToken } from 'utils/authentication';
import { useQueryClient } from 'react-query';
import { parseJSON, decode, getIv } from 'utils';
import LoginService from 'services/login';
import EventService from 'services/event';
import axios from 'axios';
import viVN from 'antd/lib/locale/vi_VN';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import MetaDataService from 'services/meta-data';
import useGetCountry from 'hooks/useGetCountry';
import useIsMobile from 'new-hooks/useIsMobile';
import 'moment/locale/vi';

export const AuthContext = React.createContext({
    isLoadingAll: false,
    user: {},
    bannerHome: [],
    loadingBanner: false,
    guestMainCart: [],
    guestEventCart: {},
    admin: {},
    host: '',
    openMasterClassFeature: false,
    permission: {},
    cart: [],
    phoneContries: [],
    isAdd: false,
    title: '',
    formatDate: 'YYYY-MM-DD',
    hideHeader: false,
    hideFooter: false,
    firebaseInstance: {},
    setIsLoadingAll: () => {},
    analyticsLogEvent: () => {},
    setGuestMainCart: () => {
        //
    },
    setGuestEventCart: () => {
        //
    },
    db: () => {
        //
    },
    auth: () => {
        //
    },
    facebookProvider: () => {
        //
    },
    googleProvider: () => {
        //
    },
    twitterProvider: () => {
        //
    },
    setPhoneContries: () => {
        //
    },
    setHideFooter: () => {
        // Innit
    },
    setHideHeader: () => {
        // Innit
    },
    setAdmin: () => {
        // Innit
    },
    setHost: () => {
        // Innit
    },
    setUser: () => {
        // Innit
    },
    setTitlePage: () => {
        // Innit
    },
    login: () => {
        // Innit
    },
    logout: () => {
        // Innit
    },
    changeLanguage: () => {
        // Innit
    },
    setCart: () => {
        // Innit
    },
});

export const useAuth = () => {
    return useContext(AuthContext);
};

const GlobalContext = ({ children }) => {
    const [user, setUser] = useState(parseJSON(localStorage.getItem(keyUser)));
    const [phoneContries, setPhoneContries] = useState([]);
    const [admin, setAdmin] = useState();
    const [host, setHost] = useState();
    const [openMasterClassFeature, setOpenMasterClassFeature] = useState(true);
    const [title, setTitle] = useState('');
    const [locale, set_locale] = useState();
    const [cart, setCart] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [permission, setPermission] = useState({});
    const [formatDate, set_formatDate] = useState('YYYY-MM-DD');
    const [hideHeader, setHideHeader] = useState(false);
    const [hideFooter, setHideFooter] = useState(false);
    const [firebaseInstance, setFirebaseInstance] = useState({});
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [loadingBanner, setLoadingBanner] = useState(false);
    const [bannerHome, setBannerHome] = useState([]);
    const { isMobile } = useIsMobile();

    const [guestMainCart, setGuestMainCart] = useState(
        localStorage.getItem(keyGuestCart) ? JSON.parse(localStorage.getItem(keyGuestCart)) : []
    );

    const [guestEventCart, setGuestEventCart] = useState(JSON.parse(localStorage.getItem(keyGuestEventCart)) || {});

    useGetCountry();
    const queryClient = useQueryClient();
    const { t, i18n } = useTranslation();

    const login = async (data) => {
        setUser(data?.user);
        saveAuthToken(data);
        queryClient.removeQueries();
    };

    const logout = () => {
        setUser(null);
        setCart([]);
        removeAuthToken();
        localStorage.removeItem(keyExpired);
        axios.defaults.headers.common.Authorization = 'Bearer ' + null;
        queryClient.removeQueries();
    };

    const setTitlePage = useCallback(
        (name) => {
            document.title = t(name);
            setTitle(name);
        },
        [t]
    );

    const changeLanguage = useCallback(
        (values) => {
            i18n.changeLanguage(values);
            axios.defaults.headers.common['X-localization'] = values;
            moment.locale(values);
            if (values === 'vi') {
                set_locale(viVN);
                set_formatDate('DD-MM-YYYY');
                return;
            }
            set_locale(enUS);
            set_formatDate('DD-MM-YYYY');
        },
        [i18n]
    );

    const getToken = useCallback(async () => {
        const { token } = await getAuthToken();
        if (token) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        }
    }, []);

    const clearTempLocalStorage = () => {
        const arr = [];
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).indexOf('temp-') === 0) {
                arr.push(localStorage.key(i));
            }
        }
        for (const value of arr) {
            localStorage.removeItem(value);
        }
    };

    const getBannerHomePage = async () => {
        try {
            setLoadingBanner(true);
            const data = await MetaDataService.getBannerHomePage(isMobile ? 'mobile' : 'desktop');
            if (data?.data?.getBanners) {
                setBannerHome(data?.data?.getBanners);
            }
            setLoadingBanner(false);
        } catch (error) {
            setLoadingBanner(false);
        }
    };

    const getUserInfo = async () => {
        const token = localStorage.getItem(keyToken) ? JSON.parse(localStorage.getItem(keyToken)) : null;
        if (!token) return;
        const data = await LoginService.getUserInformation(token);
        if (data?.data?.customerProfile) {
            localStorage.setItem(keyUser, JSON.stringify(data?.data?.customerProfile));
            setUser(data?.data?.customerProfile);
        }
    };

    const getMetaData = async () => {
        if (admin) return;
        const currentAppVersion = localStorage.getItem(keyAppVersion);
        const data = await MetaDataService.getMetaData();
        if (data?.data?.getMetaData) {
            localStorage.setItem(keyMetaData, JSON.stringify(data?.data?.getMetaData));
        }
        if (data?.data?.getMetaData?.system) {
            setAdmin(data?.data?.getMetaData?.system);
        }
        if (data?.data?.getMetaData?.host) {
            setHost(data?.data?.getMetaData?.host);
        }
        if (data?.data?.getMetaData?.permission) {
            setPermission(JSON.parse(data?.data?.getMetaData?.permission));
        }
        if (!data?.data?.getMetaData?.ecommerce_enabled) {
            setOpenMasterClassFeature(false);
        }
        if (data?.data?.getMetaData?.app_version && !currentAppVersion) {
            localStorage.setItem(keyAppVersion, data?.data?.getMetaData?.app_version || '1.0');
        }
        if (data?.data?.getMetaData?.phone_countries) {
            setPhoneContries(data?.data?.getMetaData?.phone_countries?.filter((item) => item?.enable));
        }
        return data;
    };

    const handleConnect = useCallback(async () => {
        if (window.location.pathname === '/maintain') return;
        const data = await getMetaData();
        const firebaseKey = data?.data?.getMetaData?.firebase;
        const iv = getIv(data?.data?.getMetaData?.knz, data?.data?.getMetaData?.pnz);
        const firebaseConfig = {
            authDomain: firebaseKey?.auth_domain,
            projectId: process.env.REACT_APP_PROJECT_ID_FIREBASE,
            storageBucket: firebaseKey?.storage_bucket,
            messagingSenderId: decode(firebaseKey?.messaging_sender_id, iv),
            appId: decode(firebaseKey?.api_id, iv),
            databaseURL: firebaseKey?.url_database,
            apiKey: decode(firebaseKey?.api_key, iv),
        };

        const { initializeApp } = await import('firebase/app');
        const { getAnalytics, logEvent } = await import('firebase/analytics');
        const { getDatabase } = await import('firebase/database');
        const { getAuth, GoogleAuthProvider } = await import('firebase/auth');

        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);

        const analyticsLogEvent = (eventName, eventParams) => {
            logEvent(analytics, eventName, eventParams);
        };

        setFirebaseInstance({
            analyticsLogEvent,
            db: getDatabase(app),
            auth: getAuth(app),
            googleProvider: new GoogleAuthProvider(),
        });
    }, []);

    const getGuestCartId = async () => {
        const data = await EventService.createEmptyCart();
        if (!data) return;
        localStorage.setItem(keyGuestCartId, JSON.stringify(data));
    };

    const checkCartActive = async (cartId) => {
        const data = await EventService.checkGuestCartActive(cartId);
        if (!data) {
            setGuestMainCart([]);
            localStorage.setItem(keyGuestCart, JSON.stringify([]));
            getGuestCartId();
        }
    };

    useEffect(() => {
        const guestCartId = localStorage.getItem(keyGuestCartId)
            ? JSON.parse(localStorage.getItem(keyGuestCartId))
            : null;
        if (guestCartId && !user) {
            checkCartActive(guestCartId);
        }
        if (!guestCartId && !user) {
            getGuestCartId();
        }
        getUserInfo();
        handleConnect();
    }, []);

    useEffect(() => {
        changeLanguage(localStorage.getItem('i18nextLng'));
        clearTempLocalStorage();
        getToken();
    }, [user, changeLanguage]);

    useEffect(() => {
        if (window.location.pathname !== '/maintain') {
            getBannerHomePage();
        }
    }, [isMobile]);

    const getPermissionAccess = (module, role, action) => {
        let userRole = role?.main || 'guest';
        if (module === 'ads' && action === 'create_ads') {
            userRole = role?.sub;
        }
        if (module in permission && action in permission[module] && userRole in permission[module][action]) {
            return permission[module][action][userRole];
        } else {
            return false;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isLoadingAll,
                loadingBanner,
                user,
                bannerHome,
                guestMainCart,
                guestEventCart,
                admin,
                host,
                phoneContries,
                openMasterClassFeature,
                permission,
                isAdd,
                title,
                formatDate,
                cart,
                locale,
                hideHeader,
                hideFooter,
                setIsLoadingAll,
                setHideFooter,
                setHideHeader,
                setUser,
                setGuestMainCart,
                setGuestEventCart,
                setPhoneContries,
                setTitlePage,
                setCart,
                login,
                logout,
                changeLanguage,
                setIsAdd,
                setPermission,
                getPermissionAccess,
                setAdmin,
                setHost,
                setOpenMasterClassFeature,
                ...firebaseInstance,
            }}
        >
            <ConfigProvider locale={locale}>{children}</ConfigProvider>
        </AuthContext.Provider>
    );
};
export default GlobalContext;
