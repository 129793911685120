import React from 'react';
import { routerLinks } from 'utils';

export const pages = [
    {
        layout: React.lazy(() => import('layouts/default')),
        isPublic: true,
        child: [
            {
                path: routerLinks('Home'),
                component: React.lazy(() => import('./default/home')),
                title: 'Home',
                child: [
                    {
                        path: 'post-detail/:feed_id/photo',
                        component: React.lazy(() => import('../features/post-modal')),
                        title: 'Original Post',
                    },
                ],
            },
            {
                path: routerLinks('Event'),
                component: React.lazy(() => import('./default/event/index')),
                title: 'Master Class',
            },
            {
                path: routerLinks('Event Detail') + '/:url_key',
                component: React.lazy(() => import('./default/event/event-detail')),
                title: 'Master Class Detail',
            },
            {
                path: routerLinks('End Event'),
                component: React.lazy(() => import('./default/event/end-event')),
                title: 'End Master Class',
            },
            {
                path: routerLinks('Review Event') + '/:id',
                component: React.lazy(() => import('./default/event/review-event')),
                title: 'Review Master Class',
            },
            {
                path: routerLinks('JobDetails') + '/:url_key' + '/edit',
                component: React.lazy(() => import('./default/advertisement/ad-jobs/editJob')),
                title: 'Job Detail',
            },
            {
                path: routerLinks('ForSaleDetails') + '/:url_key' + '/edit',
                component: React.lazy(() => import('./default/advertisement/ad-forsale/editForSale')),
                title: 'For Sale Detail',
            },
            {
                path: routerLinks('SettingNotification'),
                component: React.lazy(() => import('./default/setting-notifications')),
                title: 'Setting Notification',
            },
            {
                path: routerLinks('Profile') + '/:url_key',
                component: React.lazy(() => import('./default/profile')),
                title: 'Profile',
            },
            {
                path: routerLinks('Original Post') + '/:id',
                component: React.lazy(() => import('./default/post-detail/index.js')),
                title: 'Original Post',
            },
            {
                path: routerLinks('Manicurist'),
                component: React.lazy(() => import('./default/advertisement/create-ads/manicurist')),
                title: 'Manicurist',
            },
            {
                path: routerLinks('SellSalon'),
                component: React.lazy(() => import('./default/advertisement/create-ads/sell-salon')),
                title: 'Sell Salon',
            },
            {
                path: routerLinks('AdJobs'),
                component: React.lazy(() => import('./default/advertisement/ad-jobs')),
                title: 'Ads Manicurist',
            },
            {
                path: routerLinks('AdForSale'),
                component: React.lazy(() => import('./default/advertisement/ad-forsale')),
                title: 'Ads Sell Salon',
            },
            {
                path: routerLinks('JobDetails') + '/:url_key',
                component: React.lazy(() => import('./default/advertisement/ad-jobs/jobDetails')),
                title: 'Ads Details',
            },
            {
                path: routerLinks('ForSaleDetails') + '/:url_key',
                component: React.lazy(() => import('./default/advertisement/ad-forsale/forsaleDetails')),
                title: 'Ads Details',
            },
            {
                path: routerLinks('SalonDetails') + '/:id',
                component: React.lazy(() => import('./default/advertisement/salon-details')),
                title: 'Details',
            },
            {
                path: routerLinks('SalonDetails') + '/:id' + '/edit',
                component: React.lazy(() => import('./default/advertisement/edit-salon')),
                title: 'Details',
            },
            {
                path: routerLinks('SavedAds'),
                component: React.lazy(() => import('./default/advertisement/saved-ads')),
                title: 'Saved post',
            },
            {
                path: routerLinks('CreateSalon'),
                component: React.lazy(() => import('./default/advertisement/create-salon')),
                title: 'Create Salon',
            },
            {
                path: routerLinks('Feedback'),
                component: React.lazy(() => import('./default/feedback')),
                title: 'Feedback',
            },
            {
                path: routerLinks('Legal'),
                component: React.lazy(() => import('./default/legal')),
                title: 'Legal',
            },
            {
                path: routerLinks('TermAndConditions'),
                component: React.lazy(() => import('./default/legal/general-policies/TermAndConditions')),
                title: 'TermAndConditions',
            },
            {
                path: routerLinks('PrivacyPolicy'),
                component: React.lazy(() => import('./default/legal/general-policies/PrivacyPolicy')),
                title: 'PrivacyPolicy',
            },
            {
                path: routerLinks('DataSecurityPolicy'),
                component: React.lazy(() => import('./default/legal/general-policies/DataSecurityPolicy')),
                title: 'DataSecurityPolicy',
            },
            {
                path: routerLinks('CookiePolicy'),
                component: React.lazy(() => import('./default/legal/general-policies/CookiePolicy')),
                title: 'CookiePolicy',
            },
            {
                path: routerLinks('CommunityGuidelines'),
                component: React.lazy(() => import('./default/legal/general-policies/CommunityGuidelines')),
                title: 'CommunityGuidelines',
            },
            {
                path: routerLinks('AccessibilityStatement'),
                component: React.lazy(() => import('./default/legal/general-policies/AccessibilityStatement')),
                title: 'AccessibilityStatement',
            },
            {
                path: routerLinks('CommunityParticipationAgreement'),
                component: React.lazy(() =>
                    import('./default/legal/specific-agreements/CommunityParticipationAgreement')
                ),
                title: 'CommunityParticipationAgreement',
            },
            {
                path: routerLinks('SellersCommonAgreement'),
                component: React.lazy(() => import('./default/legal/specific-agreements/SellersCommonAgreement')),
                title: 'SellersCommonAgreement',
            },
            {
                path: routerLinks('BuyerAgreement'),
                component: React.lazy(() => import('./default/legal/specific-agreements/BuyerAgreement')),
                title: 'BuyerAgreement',
            },
            {
                path: routerLinks('DisputeResolutionAgreement'),
                component: React.lazy(() => import('./default/legal/general-policies/DisputeResolutionAgreement')),
                title: 'DisputeResolutionAgreement',
            },
            {
                path: routerLinks('ReturnAndRefundPolicy'),
                component: React.lazy(() => import('./default/legal/policies-for-transactions/ReturnAndRefundPolicy')),
                title: 'ReturnAndRefundPolicy',
            },
            {
                path: routerLinks('ShippingPolicy'),
                component: React.lazy(() => import('./default/legal/policies-for-transactions/ShippingPolicy')),
                title: 'ShippingPolicy',
            },
            {
                path: routerLinks('IntellectualProperty'),
                component: React.lazy(() => import('./default/legal/policies-for-transactions/IntellectualProperty')),
                title: 'IntellectualProperty',
            },
            {
                path: routerLinks('Feedback') + '/:id',
                component: React.lazy(() => import('./default/feedback/myFeedback')),
                title: 'My Feedback',
            },
            {
                path: routerLinks('CreatePost'),
                component: React.lazy(() => import('./default/create-and-edit-post')),
                title: 'Create Post',
            },
            {
                path: routerLinks('EditPost'),
                component: React.lazy(() => import('./default/create-and-edit-post')),
                title: 'Edit Post',
            },

            // =====================
            {
                path: routerLinks('Account'),
                component: React.lazy(() => import('./default/account')),
                title: 'Account',
            },
            {
                path: routerLinks('Account Verify'),
                component: React.lazy(() => import('./default/account/Verify')),
                title: 'Verify',
            },
            {
                path: routerLinks('SignUp'),
                component: React.lazy(() => import('./default/account/SignUp')),
                title: 'Sign Up',
            },
            {
                path: routerLinks('Create Profile'),
                component: React.lazy(() => import('./default/account/CreateProfile')),
                title: 'Create Profile',
            },
            {
                path: routerLinks('Success'),
                component: React.lazy(() => import('./default/account/Success')),
                title: 'Create Success',
            },
            {
                path: routerLinks('SignIn'),
                component: React.lazy(() => import('./default/account/SignIn')),
                title: 'Sign In',
            },
            {
                path: routerLinks('Forgot Password'),
                component: React.lazy(() => import('./default/account/ForgotPassword')),
                title: 'Forgot Password',
            },
            {
                path: routerLinks('Account Setting'),
                component: React.lazy(() => import('./default/account/AccountSettings')),
                title: 'Account Settings',
            },
            {
                path: routerLinks('ChangeName'),
                component: React.lazy(() => import('./default/account/AccountSettings/ChangeName')),
                title: 'Change Name',
            },
            {
                path: routerLinks('Change Primary Phone'),
                component: React.lazy(() => import('./default/account/AccountSettings/ChangePrimaryPhone')),
                title: 'Change Primary Phone Number',
            },
            {
                path: routerLinks('Change Primary Email'),
                component: React.lazy(() => import('./default/account/AccountSettings/ChangePrimaryEmail')),
                title: 'Change Primary Email',
            },
            {
                path: routerLinks('Change Password'),
                component: React.lazy(() => import('./default/account/AccountSettings/ChangePassword')),
                title: 'Change Password',
            },
            {
                path: routerLinks('Change Second Phone'),
                component: React.lazy(() => import('./default/account/AccountSettings/ChangeSecondPhone')),
                title: 'Change Second Phone Number',
            },
            {
                path: routerLinks('Change Second Email'),
                component: React.lazy(() => import('./default/account/AccountSettings/ChangeSecondEmail')),
                title: 'Change Second Email',
            },
            {
                path: routerLinks('Change Authentication Document'),
                component: React.lazy(() => import('./default/account/AccountSettings/ChangeAuthenticationDocument')),
                title: 'Change Authentication Document',
            },
            {
                path: routerLinks('Delete Account'),
                component: React.lazy(() => import('./default/account/AccountSettings/DeleteAccount')),
                title: 'Delete Account',
            },
            {
                path: routerLinks('Upgrade Account'),
                component: React.lazy(() => import('./default/account/AccountSettings/UpgradeAccount')),
                title: 'Upgrade Account',
            },
            {
                path: routerLinks('Request Details') + '/:id',
                component: React.lazy(() => import('./default/account/AccountSettings/Requests/RequestDetails')),
                title: 'Request Details',
            },
            {
                path: routerLinks('About'),
                component: React.lazy(() => import('./default/about')),
                title: 'About us',
            },
            {
                path: routerLinks('Maintain'),
                component: React.lazy(() => import('./default/maintain')),
                title: 'Maintain',
            },
            {
                path: '/terms-conditions',
                component: React.lazy(() => import('./default/account/TermsCondition')),
                title: 'Terms and conditions',
            },
            {
                path: '/*' || routerLinks('NotFound'),
                component: React.lazy(() => import('./default/not-found')),
                title: 'Not found',
            },
            {
                path: routerLinks('AccessPermissionDenied'),
                component: React.lazy(() => import('./default/access-permission-denied')),
                title: 'Not found',
            },
            {
                path: routerLinks('Forbidden'),
                component: React.lazy(() => import('./default/forbidden')),
                title: 'Forbidden',
            },
            {
                path: routerLinks('SocialLink') + '/:socialType',
                component: React.lazy(() => import('./default/account/SocialLink')),
                title: 'Social Link',
            },
            {
                path: routerLinks('SocialSuccess'),
                component: React.lazy(() => import('./default/account/Success/CreateSocialSuccess')),
                title: 'Create Success',
            },
            {
                path: routerLinks('Search'),
                component: React.lazy(() => import('./default/search')),
                title: 'Search',
            },
            {
                path: routerLinks('Add to the device'),
                component: React.lazy(() => import('./default/add-to-device')),
                title: 'Add To The Device',
            },
            {
                path: routerLinks('Report Page'),
                component: React.lazy(() => import('./default/report')),
                title: 'Report',
            },
            {
                path: routerLinks('Setting video toturial'),
                component: React.lazy(() => import('./default/setting-video')),
                title: 'Setting video toturial',
            },
            {
                path: routerLinks('Guest Create Account After Buy Product'),
                component: React.lazy(() => import('./default/account/GuestCreateAccountAfterBuyProduct')),
                title: 'Verify Password',
            },
            {
                path: routerLinks('Create Resume'),
                component: React.lazy(() => import('./default/create-resume')),
                title: 'Create Resume',
            },
            {
                path: routerLinks('Product Detail') + '/:sku',
                component: React.lazy(() => import('./default/product-detail')),
                title: 'Product Detail',
            },
            {
                path: routerLinks('Vote'),
                component: React.lazy(() => import('./default/vote')),
                title: 'Vote',
            },
            {
                path: routerLinks('Vote') + '/:url_key',
                component: React.lazy(() => import('./default/vote-detail')),
                title: 'Vote',
            },
        ],
    },
];

export const arrayPaths = [];

pages.forEach((layout) => {
    const paths = [];
    layout.child.forEach((page) => {
        paths.push(page.path);
        return page;
    });
    arrayPaths.push(paths);
    return layout;
});
